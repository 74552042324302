<template>
  <div>
    <v-card loader-height="2" :loading="loading" :disabled="loading">
      <v-card-text>
        <v-row>
          <v-col cols="6" class="border-right px-0">
            <v-card class="shadow-off">
              <v-card-text class="pt-0 d-flex">
                <v-btn
                  fab
                  color="primary"
                  elevation="0"
                  small
                  class="mr-4"
                  @click="
                    addflag = true;
                    edit = false;
                    modifydata = {};
                  "
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
                <v-text-field
                  label="Search"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
                <v-btn icon @click="refreshData()" class="ml-2"
                  ><v-icon>mdi-refresh</v-icon></v-btn
                >
              </v-card-text>

              <v-list two-line v-if="listdata.length > 0">
                <v-slide-x-transition class="py-0" group>
                  <template v-for="(v, k) in listdata">
                    <v-list-item
                      :key="k"
                      link
                      class="px-0 border-hover left py-0"
                      @click="
                        modifydata = { ...v };
                        selectedindex = k;
                        editflag = false;
                        add = false;
                      "
                    >
                      <v-list-item-content class="pl-4">
                        <v-list-item-title>
                          {{ v.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{
                          v.contactemail
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip small class="overline">{{ v.type }}</v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-slide-x-transition>
              </v-list>
              <div v-else>
                <v-alert type="info" :value="true" text border="left">
                  Add new Entity
                </v-alert>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6">
            <div v-if="addflag || editflag">
              <div class="pb-4 d-flex title align-center">
                <span v-if="addflag" class="">Add New Entity</span>
                <span v-else-if="editflag" class="">Edit Entity Details</span>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="isEnabled"
                  class="text-caption"
                  :label="isEnabled ? 'SOD Enable' : 'SOD Disable'"
                  @change="handleSwitchChange"
                />
              </div>
              <lb-string
                label="Name"
                v-model="modifydata['name']"
                :error="errors['name']"
              />
              <lb-dropdown
                label="Type"
                v-model="modifydata['type']"
                :error="errors['type']"
                :items="selectdata.type"
              />
              <lb-string
                label="Contact Person"
                v-model="modifydata['contactperson']"
                :error="errors['contactperson']"
              />
              <lb-string
                label="Email"
                v-model="modifydata['contactemail']"
                :error="errors['contactemail']"
              />
              <lb-number
                label="Contact No."
                v-model="modifydata['contactno']"
                :error="errors['contactno']"
              />
              <lb-dropdown
                v-model="modifydata['industry']"
                :items="industries"
                label="Industry*"
                :error="errors['industry']"
              ></lb-dropdown>
              <lb-dropdown
                v-model="modifydata['sector']"
                :items="sectors[modifydata['industry']]"
                label="Sector*"
                :error="errors['sector']"
              ></lb-dropdown>
              <lb-dropdown
                v-model="modifydata['size']"
                :items="size"
                label="Size*"
                :error="errors['size']"
              ></lb-dropdown>
              <lb-string
                label="Address"
                v-model="modifydata['address']"
                :error="errors['address']"
              />
              <lb-dropdown
                label="Plan"
                v-model="modifydata['plan']"
                :error="errors['plan']"
                :items="plans"
                :multiple="false"
              />
              <lb-dropdown
                label="Account Manager"
                v-model="modifydata['accountmanager']"
                :error="errors['accountmanager']"
                :items="allUsers"
                multiple
                itemtext="name"
                itemvalue="_id"
              />
              <div class="text-center pb-4">
                <v-btn
                  @click="
                    addflag = false;
                    editflag = false;
                    modifydata =
                      listdata.length >= selectedindex + 1 &&
                      listdata.length > 0
                        ? listdata[selectedindex]
                        : {};
                  "
                  text
                  class="mr-2"
                  >Cancel</v-btn
                >
                <v-btn
                  v-if="addflag"
                  color="primary"
                  @click="save()"
                  elevation="0"
                  >Save</v-btn
                >
                <v-btn
                  v-else-if="editflag"
                  color="primary"
                  @click="update()"
                  elevation="0"
                  >Update</v-btn
                >
              </div>
            </div>
            <div v-else>
              <div class="pb-4 d-flex align-center">
                <span class="title">View Entity Details</span>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  v-if="
                    listdata.length >= selectedindex + 1 && listdata.length > 0
                  "
                  @click.stop="
                    $nova.gotoLink({
                      path: '/admin/client/id/' + modifydata._id,
                    })
                  "
                  ><v-icon>mdi-account-eye</v-icon></v-btn
                >
                <v-btn
                  icon
                  v-if="
                    listdata.length >= selectedindex + 1 && listdata.length > 0
                  "
                  @click="
                    editflag = true;
                    add = false;
                    errors = {};
                  "
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </div>

              <v-simple-table
                dense
                v-if="
                  listdata.length >= selectedindex + 1 && listdata.length > 0
                "
              >
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Name:</td>
                      <td>{{ modifydata.name }}</td>
                    </tr>
                    <tr>
                      <td>Type:</td>
                      <td>{{ modifydata.type }}</td>
                    </tr>
                    <tr>
                      <td>Contact Person:</td>
                      <td>{{ modifydata.contactperson }}</td>
                    </tr>
                    <tr>
                      <td>Email:</td>
                      <td>{{ modifydata.contactemail }}</td>
                    </tr>
                    <tr>
                      <td>Contact number:</td>
                      <td>{{ modifydata.contactno }}</td>
                    </tr>
                    <tr>
                      <td>Address:</td>
                      <td>{{ modifydata.address }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-else>
                <v-alert type="info" :value="true" text border="left">
                  No details available
                </v-alert>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
// import pageHeader from '@/components/pageHeader'
// import managelist from '@/components/ManageList'

export default {
  name: "clientv2",
  // props: ['props'],
  data: function () {
    return {
      pageprops: {
        name: "Client",
        breadcrumbs: [],
      },
      listdata: [],
      plans: [
        {
          value: "62a2e0118f760000ea00694a",
          name: "Information Workflow - Free",
        },
        {
          value: "62a2e01f8f760000ea00694b",
          name: "Information Workflow - Paid",
        },
        { value: "630723c2db500000a0003ea7", name: "Earnout" },
        { value: "62fba20ba107000006001136", name: "TDS - AR" },
      ],
      plansid: {
        "62a2e0118f760000ea00694a": "Information Workflow - Free",
        "62a2e01f8f760000ea00694b": "Information Workflow - Paid",
        "630723c2db500000a0003ea7": "Earnout",
        "62fba20ba107000006001136": "TDS - AR",
      },
      loading: false,
      addflag: false,
      editflag: false,
      selectedindex: 0,
      modifydata: {},
      errors: {},
      selectdata: {
        type: [
          { name: "Individual", value: "Individual" },
          { name: "Company", value: "Company" },
          { name: "Firm", value: "Firm" },
          { name: "Trust", value: "Trust" },
          { name: "Government", value: "Government" },
          { name: "Others", value: "Others" },
        ],
      },
      allUsers: [],
      industries: [
        "Agriculture",
        "Construction",
        "Consumer Services",
        "Business Services",
        "Education",
        "Energy, Utilities & Waste",
        "Finance",
        "Healthcare Services",
        "Holding Companies & Conglomerates",
        "Hospitality",
        "Hospitals & Physicians Clinics",
        "Insurance",
        "Law Firms & Legal Services",
        "Manufacturing",
        "Media & Internet",
        "Minerals & Mining",
        "Organizations",
        "Real estate",
        "Software",
        "Telecommunications",
        "Transportation"
      ],
      sectors: {
        "Agriculture": ["Animals & Livestock", "Crops", "Forestry"],
        "Construction": ["Architecture, Engineering & Design", "Civil Engineering", "Construction", "Commercial & Residential Co"],
        "Consumer Services": ["Automotive Service & Collision Repair", "Barber Shops & Beauty Salons", "Car & Truck Rental", "Childcare", "Cleaning Services", "Funeral Homes & Funeral Related Services", "Landscape Services", "Photography Studio", "Repair Services", "Weight & Health Management"],
        "Business Services": ["Accounting Services", "Advertising & Marketing", "Call Centers & Business Centers", "Chambers of Commerce", "Commercial Printing", "Custom Software & IT Services", "Debt Collection", "Facilities Management & Commercial Cleaning", "Food Service", "HR & Staffing", "Information & Document Management", "Management Consulting", "Multimedia & Graphic Design", "Research & Development", "Security Products & Services", "Translation & Linguistic Services"],
        "Education": ["Colleges & Universities", "K-12 Schools", "Training"],
        "Energy, Utilities & Waste": [
          "Electricity",
          "Oil & Gas Exploration & Services",
          "Waste Treatment, Environmental Services & Recycling",
          "Water Treatment"
        ],
        "Finance": [
          "Banking",
          "Credit Cards & Transaction Processing",
          "Investment Banking",
          "Lending & Brokerage",
          "Venture Capital & Private Equity"
        ],
        "Government": ["Federal", "Local", "State", "Tribal Nations"],
        "Healthcare Services": ["Ambulance Services", "Blood & Organ Banks", "Elderly Care Services", "Medical Laboratories & Imaging Centers", "Mental Health & Rehabilitation Facilities", "Veterinary Services"],
        "Holding Companies & Conglomerates": [],
        "Hospitality": ["Amusement Parks", "Arcades & Attractions", "Cultural & Informational Centers", "Fitness & Dance Facilities", "Gambling & Gaming", "Libraries", "Lodging & Resorts", "Movie Theaters", "Museums & Art Galleries", "Performing Arts Theaters", "Restaurants", "Sports Teams & Leagues", "Travel Agencies & Services", "Zoos & National Parks"],
        "Hospitals & Physicians Clinics": ["Dental Offices", "Medical & Surgical Hospitals", "Medical Specialists", "Physicians Clinics"],
        "Insurance": [],
        "Law Firms & Legal Services": [],
        "Manufacturing": ["Aerospace & Defense", "Appliances", "Automotive Parts", "Boats & Submarines", "Building Materials", "Chemicals & Related Products", "Cleaning Products", "Computer Equipment & Peripherals", "Cosmetics, Beauty Supply & Personal Care Products", "Electronics", "Food & Beverage", "Furniture", "Glass & Clay", "Hand, Power & Lawn-care Tools", "Health & Nutrition Products", "Household Goods", "Industrial Machinery & Equipment", "Medical Devices & Equipment", "Motor Vehicles", "Pet Products", "Pharmaceuticals", "Photographic & Optical Equipment", "Plastic, Packaging & Containers", "Pulp & Paper", "Sporting Goods", "Telecommunication Equipment", "Test & Measurement Equipment", "Textiles & Apparel", "Tires & Rubber", "Toys & Games", "Watches & Jewelry", "Wire & Cable"],
        "Media & Internet": ["Broadcasting", "Data Collection & Internet Portals", "Music Production & Services", "Newspapers & News Services", "Publishing", "Social Networks", "Ticket Sales"],
        "Minerals & Mining": [],
        "Organizations": ["Membership Organizations", "Non-Profit & Charitable Organizations", "Religious Organizations"],
        "Real estate": [],
        "Retail": ["Apparel & Accessories Retail", "Auctions", "Automobile Dealers", "Automobile Parts Stores", "Consumer Electronics & Computers Retail", "Convenience Stores, Gas Stations & Liquor Stores", "Department Stores, Shopping Centers & Superstores", "Drug Stores & Pharmacies", "Flowers, Gifts & Specialty Stores", "Furniture", "Grocery Retail", "Home Improvement & Hardware Retail", "Jewelry & Watch Retail", "Office Products Retail & Distribution", "Other Rental Stores (Furniture, A/V, Construction & Industrial Equipment)", "Pet Products", "Record, Video & Book Stores", "Sporting & Recreational Equipment Retail", "Toys & Games", "Vitamins, Supplements & Health"],
        "Software": ["Business Intelligence (BI) Software", "Content & Collaboration Software", "Customer Relationship Management (CRM) Software", "Database & File Management Software", "Engineering Software", "Enterprise Resource Planning (ERP) Software", "Financial Software", "Healthcare Software", "Human Resources Software", "Legal Software", "Mobile App Development", "Multimedia, Games & Graphics Software", "Networking Software", "Security Software", "Storage & System Management Software", "Supply Chain Management (SCM) software"],
        "Telecommunications": ["Cable & Satellite", "Internet Service Providers", "Website Hosting & Internet-related Services", "Telephony & Wireless"],
        "Transportation": ["Airlines", "Airports & Air Services", "Freight & Logistics Services", "Marine Shipping & Transportation", "Rail, Bus & Taxi", "Trucking, Moving & Storage"]
      },
      size: ["0 - 50 million", "50 - 150 million", "$150 - 500 million", "500 to 1bn", "Greater than 1bn"],
      isEnabled:false
    };
  },
  // components: {
  //   // pageHeader,managelist
  // },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.errors = {};
      this.addflag = false;
      this.editflag = false;
      this.getData();
    },
    getData() {
      this.loading = true;
      this.axios
        .post("/v2/client/get")
        .then((dt) => {
          if (dt.data.status === "success") {
            this.listdata = dt.data.data;
            if (Object.keys(this.modifydata).length === 0)
              this.modifydata = {
                ...(this.listdata.length > 0 ? this.listdata[0] : {}),
              };

            this.axios
              .post("/v2/client/getallusers")
              .then((dt) => {
                if (dt.data.status === "success") {
                  console.log("dt.data.data",dt.data.data);
                  this.allUsers = dt.data.data
                }
              })
              .catch((err) => { this.$store.commit("sbError",err.message || err || "Unknown error!");
                console.log(err);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            console.log("Error fetching data");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      this.loading = true;
      this.axios
        .post("/v2/client/add", { data: [this.modifydata] })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "Client Added");
            this.$nova.gotoLink({
              path: "/admin/client/id/" + (dt.data.data[0] || {})._id,
            });
            this.refreshData();
            this.$store.commit("refreshClient", true);
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            throw dt.data.message || "Error adding client";
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      if (this.modifydata._id) {
        this.loading = true;
        this.axios
          .post("/v2/client/edit", {
            data: [this.modifydata],
            id: this.modifydata._id,
          })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.$store.commit("sbSuccess", "Client Updated");
              this.refreshData();
              this.$store.commit("refreshClient", true);
            } else {
              this.errors = {};
              if (dt.data.data.length > 0) {
                if (dt.data.data[0].index0)
                  this.errors = dt.data.data[0].index0;
              }
              throw dt.data.message || "Error updating client";
            }
          })
          .catch((err) => {
            this.$store.commit(
              "sbError",
              err.message || err || "Unknown error!"
            );
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      } else console.log("No ID found to update");
    },
    handleSwitchChange() {
      let payload = {"sod": this.isEnabled,
        "client_id": this.modifydata._id
      }
      this.axios.post("/v2/client/switchSod", {data: payload}).then((dt) => {
          if (dt.data.status === "success") {
              this.refreshData()
            }
          }
        )
    }
  },
  computed: {},
  watch: {},
};
</script>